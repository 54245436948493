import styled from '@emotion/styled'
import { Line } from 'app/components/Common/Line'
import React, { memo } from 'react'

export interface Props {
  description?: string
  title?: string
}

export const PolicyParagraph = memo(function PolicyParagraph({
  description,
  title,
}: Props) {
  if (!description || !title) {
    return null
  }

  return (
    <Container>
      <Wrapper>
        {title ? (
          <Title>
            {title}
            <Line />
          </Title>
        ) : null}

        {description ? (
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        ) : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section``

const Wrapper = styled.div`
  margin: 5.625rem auto 0;
  padding: 7.5rem 9.722vw 5.625rem;

  @media (max-width: 1199px) {
    padding: 1.875rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3rem;
  font-weight: 500;
  line-height: 4rem;
  max-width: 28.75rem;
  text-align: left;

  > div {
    margin: 1.25rem 0 0;
  }

  @media (max-width: 1199px) {
    font-size: 2.25rem;
    line-height: 2.875rem;

    > div {
      margin-top: 1rem;
    }
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  line-height: 1.875rem;
  margin: 3.75rem 0 0;

  a {
    color: ${({ theme }) => theme.colors.variants.neutralDark2};
    text-decoration: underline;
  }
  p {
    margin-block-end: 1em;

    &:last-of-type {
      margin-block-end: 0;
    }
  }
  ul {
    margin-bottom: 1em;
    padding: inherit;
    li {
      padding-left: 1.25rem;
      position: relative;
      &:before {
        content: '';
        width: 0.5rem;
        height: 0.5rem;
        background: ${({ theme }) => theme.colors.variants.primaryDark};
        border-radius: 50%;
        position: absolute;
        top: 0.9375rem;
        left: 0;
      }
    }
  }

  @media (max-width: 1199px) {
    font-size: 1rem;
    line-height: 1.75rem;
    margin-top: 2.25rem;
  }

  @media (max-width: 1023px) {
    ul {
      li {
        &:before {
          top: 0.875rem;
        }
      }
    }
  }
`
